<template>
    <div id="invite">
        <div class="container">
            <img style="z-index: 0; left:0; width: 100%; position: absolute;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/CastleIntro.jpg">
            <div class='row justify-content-md-center' style="z-index: 1; position: relative;">
            <div class="col-xs-12 col-md-6 col-12 outside-white" style="color:white; margin-top:60px;">
                <h1>Invite</h1>
                <p>Add a Friend to your adventure!</p>
            </div>
            <div class="w-100"></div>
            <div class="col-12">
                <div class="card">
                <form @submit.prevent style='min-height: 200px; padding:20px;'>
                    <h4 style="margin-top:20px">Invite </h4>
                    <div class="input-group mb-3">
                    <label class='label-helper' for="email1">Email</label>
                    <input v-model.trim="InviteForm.email" type="text" placeholder="you@email.com" id="email1" class="form-control" />
                    </div>
                    <button @click="createInvite" class="button btn btn-primary" style="margin-top:10px;">Invite</button>
                </form>
                </div>
                <transition name="fade">
                    <div v-if="errorMsg !== ''" class="error-msg">
                        <p>{{ errorMsg }}</p>
                    </div>
                </transition>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    const fb = require('../firebaseConfig.js')
    export default {
        props: {
          query: {
            type: String,
            default: () => ''
          },
          adventureId: {
            type: String,
            default: () => ''
          },
          type: {
            type: String,
            default: () => ''
          }
        },
         data() {
            return {
                InviteForm: {
                    email: '',
                },
                errorMsg: ''
            }

    },
    methods: {
    async createInvite(){
        console.log('invite')
        console.log(this.InviteForm)
        var userID = this.$store.state.currentUser.uid;
        await fb.invitesCollection.add({
      createdOn: new Date(),
      creatorId: userID,
      type: this.type,
      characterId: this.query,
      email: this.InviteForm.email,
      adventureId: this.adventureId,
  }).then(ref => {
          console.log(ref)
          this.errorMsg = 'Success! Sent'
          this.$emit('toggleInvite');
          console.log('toggle')
        }).catch(err => {
          console.log(err)
          this.errorMsg = 'Failed Try again.'
        });
        }
        }
    }
</script>
