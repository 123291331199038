<template>
  <div class="character_section">
    <div class="row justify-content-md-center " style='margin-bottom: 8px;'>
     <nav class="navbar-fixed-top navbar fixed-top navbar-expand-lg navbar-top justify-content-center" >
      <router-link class="navbar-brand" to="/main" style="font-size: 14px;">
    <!-- <img src="/docs/4.0/assets/brand/bootstrap-solid.svg" width="30" height="30" class="d-inline-block align-top" alt=""> -->
    <div class='pocket-brand-wrap ' style="width:20px; height:20px;  float:left; border-radius: 3px; margin-right:4px; ">
      <div style=" padding:1px;"><i class="ra ra-perspective-dice-random" style="font-size: 14px;"></i></div></div>
    Pocket RPG
  </router-link>
    </nav>
      </div>
    <div class="row justify-content-md-center " style='margin-bottom: 8px;'>
      <div class="col-xs-12" style='margin-bottom: 8px;'>
    <h1 class="outside-white" style="margin-top:60px; color:black;" >Character Create</h1>
    <span v-if="playTest" class="text-sm text-success">PlayTest: {{playTest}}</span>
  </div>
</div>
<div v-if="showScenario" class="row justify-content-md-center history">
  <div class='col-xs-12 col-md-6'>
  <div class="card character-card">
  <div class="card-body">
    <h5 class="card-title">Current Setting</h5>
    <img style="width: 100%; margin-bottom: 20px;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/CastleIntro.jpg">
     <p class="card-text" style="text-align:left;">Welcome to the <b>Shattered Lands</b>,<br> What was once known as Hallamar are now called the <b>Shattered Lands</b>. The Great Reckoning created a line between the creatures of magic and darkness and the world of the light. It is said that there was once great magic throughout the kingdom but it was sucked into the Shattered Lands right before the Reckoning. Now magic can only be created with Fell Stones that are brought back from the lands. The Warlocks, warrior monks chosen from the kingdom lead expeditions into the lands and act as shepards for the Houses of Hallamar.
     </p>
     <p class="card-text" style="text-align:left;">The Great Houses of Hallamar send heros into the Shattered Lands to discover secrets and to compete for power. <br><br>Good Luck Exploring!</p>
     <p class="card-text"><a v-if='!loadingCharacter' href="#" class="" @click.prevent="toggleCampaignInfo()" >Back to Character Create</a></p>
</div>
</div>
</div>
</div>
<div v-else class="row justify-content-md-center history">
  <div class='col-xs-12 col-md-6'>
    <div class="card character-card">
      <img v-if="adventure && adventure.image_src" class="" style="!position: absolute; height:600px;" :src="adventure.image_src" alt="Card image cap">
      <img v-else class="card-image-marquee-create" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png" alt="Card image cap">
      <div class="card-body" style="    background: rgba(0, 0, 0, .5); color: white;">
        <h5 v-if="adventure && adventure.title">{{adventure.title}}</h5>
        <!-- <h5 v-else class="card-title">Our hero</h5> -->
        <p v-if="adventure" class="card-text" style="font-size: 14px; text-align: left;" v-html="adventure.description"></p>
        <!-- <p v-if="adventure" class="card-text" style="font-size: 14px; text-align: left;">{{adventure.description}}</p> -->
        <p v-else class="card-text">Campaign: Shattered Lands <a v-if='!loadingCharacter' href="#" class="" @click.prevent="toggleCampaignInfo()" >more info</a></p>
         <div v-if="adventure && adventure.description" class="outgoing_msg"><h6>Adventure Goals</h6>
            <div>
            <div v-for="(goal) in adventure.goals" v-bind:key='goal' >
              <i :class="'ra '+tagLogo(goal.type)"></i> {{goal.description}}
          </div>
        </div>
          </div>
          <div v-else class="outgoing_msg"><h6>Character Goals</h6>
            <div>
            <div v-for="(goal) in characterGoals" v-bind:key='goal' >
              <i :class="'ra '+tagLogo(goal.type)"></i> {{goal.description}}
          </div>
        </div>
          </div>
        <form @submit.prevent class='clearfix'>
           <div class="small-image" style="float:left;">
                <img v-if="CharacterForm.src" class=""  :src="CharacterForm.src" :style="characterImage(CharacterForm.src)">
                  <img v-else  class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
          </div>
          <button type="button" class="btn btn-light btn-sm" style="float:left;" @click.prevent="NextImage()">Next Image</button>
          <a v-if='!loadingCharacter' href="#" class="btn btn-light btn-sm" style="float:left;"  @click.prevent="HelpGenerateCharacter">Generate Character</a>
          <label class="text-start" >Generate will fill in any empty inputs and create a companion and nemisis for you.</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
          </div>
           <label class="label-helper" for="name">Name</label>
          <input id="name" type="text" v-model.trim="CharacterForm.name" class="form-control" placeholder="Name" aria-label="Character" aria-describedby="basic-addon1">
        </div>
         <div class="input-group mb-3">
          <div class="input-group-prepend">
            <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
          </div>
           <label class="label-helper" for="name">Class</label>
          <input id="class" type="text" v-model.trim="CharacterForm.class" class="form-control" placeholder="Class" aria-label="Class" aria-describedby="basic-addon1">
        </div>
        <div class="input-group mb-3">
         
             <label class="label-helper" for="description">Description</label>
          <textarea class="form-control larger" id="description" v-model.trim="CharacterForm.description" aria-label="With textarea" placeholder="They had long dark hair, golden armor, and a scar...."></textarea>
        </div>
        <!-- <div class="banter-tags" style="clear:both; background:white;">Hooks<div class="banter-tags" v-html="motivationTags"></div></div> -->
        <div class="input-group mb-3">
          <label class="label-helper" for="epic_motivation">Epic Motivation</label>
          <textarea id='epic_motivation' class="form-control larger" v-model.trim="CharacterForm.epic_motivation" v-on:keyup="checkInfo" aria-label="With textarea" placeholder="Motivated to save their family..."></textarea>

        </div>
        <div class="input-group mb-3">
          <label class="label-helper" for="skills">Skills</label>
          <textarea id='skills' class="form-control larger" v-model.trim="CharacterForm.skills" v-on:keyup="checkInfo" aria-label="With textarea" placeholder="Expert Archer, Fireball, Healing"></textarea>

        </div>
        <div class="input-group mb-3">
          
          <label class="label-helper" for="trivial_motivation">Trivial Motivation</label>
          <textarea id='trivial_motivation' class="form-control larger" v-model.trim="CharacterForm.trivial_motivation" v-on:keyup="checkInfo"  aria-label="With textarea" placeholder="Always interested in finding the right hat."></textarea>
        </div>
        <div class="input-group mb-3">
          <label class="label-helper" for="hate">Hates...</label>
          <textarea id='hates' class="form-control larger" v-model.trim="CharacterForm.hates" aria-label="With textarea" v-on:keyup="checkInfo"  placeholder="Cant stand snakes..."></textarea>
        </div>
        <div>
          <div v-if='loadingCompanion'>
         Loading Companion & Nemisis
         <div  class="spinner-border text-success" role="status">
         
            <span class="sr-only"><i class="ra  ra-helmet true"></i></span>
          </div><br>
        </div>
        <div v-else-if="companionObject.name">
          <h6>Companion</h6> 
          <div class="clearfix"><div class="small-image float-start"><img v-if="companionObject.src" class=""  :src="companionObject.src" :style="characterImage(companionObject.src)">
                  <img v-else  class="small-image float-left" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
           </div><div style="float:left;">{{companionObject.name}} the {{companionObject.class}}</div> </div>
          <br>
          <div v-if="nemisisObject && nemisisObject.name">
          <h6>Nemisis</h6>
          <div class="clearfix"><div class="small-image float-start" ><img v-if="nemisisObject.src" class=""  :src="nemisisObject.src" :style="characterImage(nemisisObject.src)">
                  <img v-else  class="small-image float-left" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
                   </div><div style="float:left;">{{nemisisObject.name}} the {{nemisisObject.class}}</div></div>
        </div>
        <br>
      </div>

        </div>
        <div v-if='loadingCharacter'>
         Loading Character
         <div  class="spinner-border text-success" role="status">
         
            <span class="sr-only"><i class="ra  ra-helmet true"></i></span>
          </div><br>
        </div>
        <button v-if='!loadingCharacter' :disabled="loadingAll" href="#" class="btn btn-primary" @click.prevent="CreateCharacter" style="margin-left:10px;">Create Character</button>
      </form>
      </div>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
   //removed
   // import { mapState } from 'vuex'
    const fb = require('../../firebaseConfig.js');

    // const encounters = require('../../helpers/encounters.js');
    import {generate_encounters} from  '../../helpers/encounters.js'
    import {goals,character, companion, nemisis} from '../../helpers/generator.js'
    import { toRaw } from 'vue'
    export default {
         data() {
            return {
                showScenario: false,
                loadingCharacter: false,
                loadingCompanion:false,
                loadingAll: false,
                companionObject: {},
                nemisisObject: {},
                adventure: {},
                playTest: '',
                imageIndex:0,
                skills: [],
                characterGoals: [],
                CharacterForm: {
                    src:'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=157.328125&y=10.203125&width=421.65625&height=240.9375',
                    class: '',
                    pateron: '',
                    epic_motivation:'',
                    trivial_motivation:'',
                    skills:'',
                    hates:'',
                    items:[],
                    npcs:[],
                    locations:[],
                    factions: [],
                    motivationTags:'<span class="time_date">You can add Hooks by using<br> @Location, $Item, &NPC, or #EncounterType.<br> ex. &TheDarkKnight looks a you.<br> Creates <div><div class="banter-tag center"><i class="ra  ra-helmet"></i> The Dark Knight</div></div><br> and saves the text as<br> The Dark Knight looks a you.</span>',
                    first_event_scene: '',
                }
              }
            },
            beforeMount(){
              console.log()
              if(this.$route.query.adventure){
                this.loadAdventure(this.$route.query.adventure)
              }
              if(this.$route.query.playtest){
                this.playTest = this.$route.query.playtest;
                console.log(this.playTest)
              }
              },
              methods: {
                character,
                companion,
                nemisis,
                generate_encounters,
                goals,
                toggleCampaignInfo(){
                  this.showScenario = !this.showScenario;
                },
                MatchImage(){
                  // Placeholder not used.
                  [{'key': 'man red barbarian','src':'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=51.25&y=14.203125&width=697.5&height=398.5625'},
                  {'key': 'man rouge','src':'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=175.25&y=10.203125&width=697.5&height=398.5625'}
                  ]
                },
                NextImage(random){
                  // console.log('move')
                  var start_images = [
                  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=157.328125&y=10.203125&width=421.65625&height=240.9375',
                  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=98.328125&y=1.203125&width=421.65625&height=240.9375',
                  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=149.328125&y=126.203125&width=421.65625&height=240.9375',
                  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=70&y=90&width=341&height=194.84375',
                  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=60.25&y=212.203125&width=697.5&height=398.5625',
                  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=170.25&y=210.203125&width=697.5&height=398.5625',
                  'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=625.25&y=216.203125&width=697.5&height=398.5625']
                  if(!random){
                    this.imageIndex = this.imageIndex+1
                  if(this.imageIndex > start_images.length-1){
                    this.imageIndex = 0
                  }
                  // console.log(this.imageIndex)
                  this.CharacterForm.src = start_images[this.imageIndex]
                }
                return start_images[Math.floor(Math.random()*start_images.length)]
                  
                },
                characterImage(src){
      var response = '';
      var calc = {'y':0,'x':0,'height':0,'width':0}
      var pop = src.split('?')
      pop = pop[1]
      pop = pop.split('&')
      pop.forEach(function(pair) {
        var key = pair.split('=')[0];
        var value = pair.split('=')[1];
        calc[key] = value;
      });
      response = 'position:absolute; left:-'+calc['x']+'px; top:-'+calc['y']+'px; height:'+Math.round(calc['height'])+'px; width:'+Math.round(calc['width'])+'px;';
      // console.log(response)
      return response
    },
     tagLogo(type){
      var logos = {
        'item':'ra-torch',
        'npc':'ra-helmet',
        'heart':'ra-two-hearts',
        'rumor':'ra-x-mark',
        'faction':'ra-crown',
        'creature':'ra-hydra',
        'info':'ra-book',
      }
      return logos[type]
    },
    checkInfo(event){
      console.log('check info')
      // check
      var banter_tags ='';
      var splice = event.target.value.split(' ');

      // Get Characters, Locations, NPCs, and items
      const items = this.items || [];
      const npcs = this.npcs || [];
      const locations = this.locations || [];
      
      //removed ,index...
      splice.forEach(function (item) {
        // console.log(item, index);
        // console.log(item);
        var color = ''
        var shape =''
        var defined = item.split(/(?=[A-Z])/);
        var check = defined.join(" ")
        
        item = check.replace(/([a-z])([A-Z])/g, '$1 $2');
        item = item.replace(/[^a-zA-Z $ @ # & %]/g, "");
        
        if(item.includes('#')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-scroll-unfurled"></i> '+item.replace('#','')+'</div>';
        }
        if(item.includes('@')){
          // 
          // locations.push({'name':item.replace('@','')})
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-pyramids"></i> '+item.replace('@','')+'</div>';
        }
        if(item.includes('$')){
          // items.push({'name':item.replace('$','')})
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-torch"></i> '+item.replace('$','')+'</div>';
        }
        if(item.includes('&')){
          // npcs.push({'name':item.replace('&','')})
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-helmet"></i> '+item.replace('&','')+'</div>';
        }
        if(item.includes('%')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-perspective-dice-random"></i> '+item.replace('%','')+'</div>';
        }
        

        //removed ,index...
        items.forEach(function (thing) {
          // console.log('this')
          // console.log(thing['name'].toLowerCase());
          // console.log(item.toLowerCase());
          if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
            color = 'blue'
            shape='ra-torch'

          }
        });
        //removed ,index...
        npcs.forEach(function (thing) {
          if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
            color = 'red'
          shape ='ra-helmet'
          
          }
        });
        //removed ,index...
        locations.forEach(function (thing) {
          if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
            color = 'orange'
          shape = 'ra-pyramids'
          }
        });

        if(color!=''){
          banter_tags = banter_tags+'<div class="banter-tag '+color+'"><i class="ra '+shape+'"></i> '+item+'</div>';
        }

        
        });
      // console.log(banter_tags);
      // console.log('banter here')
      if(banter_tags==''){
        banter_tags= '<span class="time_date">You can add Hooks by using<br> @Location, $Item, &NPC, or #EncounterType.<br> ex. &TheDarkKnight looks a you.<br> Creates <div><div class="banter-tag center"><i class="ra  ra-helmet"></i> The Dark Knight</div></div><br><br> The Dark Knight looks a you.</span>'
      }
      this.motivationTags = banter_tags;
      this.items = items;
      this.npcs = npcs;
      this.locations = locations;
      
    },
    loadAdventure: function(adventureID){
      console.log('loading adventure '+adventureID)
      fb.adventuresCollection.doc(adventureID).onSnapshot(snapshot=>{
        if (snapshot.exists) {
          // console.log('snapshot')
          let adventure = snapshot.data();
          adventure.id = snapshot.id;
          this.adventure = adventure 
          // console.log(adventure)
          if(this.adventure.companion){
            this.companionObject = this.adventure.companion
          }
          if(this.adventure.nemisis){
            this.nemisisObject = this.adventure.nemisis
          }
          console.log(this.adventure)
        } else {
                // snapshot.data() will be undefined in this case
                console.log("No such document!");
              }  
            })
    },
  async setFirstScene(name,motivation){
                var location_name = 'The city of Mesa'
                console.log(this.adventure)
                if(this.adventure && Object.keys(this.adventure).length != 0){
                  console.log('adventure')
                  location_name = this.adventure.location.name
                }
                var prompt = "You are a Game Master in a roleplaying game, a character is just starting out in "+location_name+
                "\n. The player's character name is "+name+'.'
                "\n Their motivation: '"+motivation+
                "\n The goals for the player in the world: "+JSON.stringify(this.characterGoals);

                  if('name' in this.companionObject){
                    var companion_name = this.companionObject['name']
                    prompt = prompt+ "\n The character has arrived to Mecia with their companion "+companion_name+"."
                  }
                  if(this.adventure){
                    prompt = prompt + "\n This is current adventure the description: "+this.adventure.description+
                    "\n Create an initial 50 word hook where an NPC introduces themselves and the character is presented with a quest related to one of their goals."
                  }else{

                  prompt = prompt + "'.The player needs to discover additional information to unlock knowledge for their quest. Mecia is at the edge of the Shattered Lands and Warlocks provide saftey to parties that venture in the lands. The player can arrive at the Iron Docks, the West Docks, or could be at a tavern in the Whipsers district. "+
                    "\n Create an initial 50 word hook where an NPC introduces themselves and the character is presented with a quest related to one of their goals."
                  }

                  if(this.playTest !=''){
                    prompt = "You are a Game Master in a roleplaying game."+
                    "\n a Character is located in "+location_name+"."+
                    "\n The player's character name is "+name+" and described their motivation as '"+motivation+
                    "\n The goals for the player in the world:"+this.characterGoals+ 
                    "\n The player is play testing a scene that has this type: "+this.playTest+
                    "\n Create an initial 50 word hook where the character is presented with the situation and a goal they need to acomplish in the scene."+
                    "\n For example, if the Scene is combat introduce their opponent, if its exploration introduce something they should explore."
                  }
                  console.log('prompt')
                  console.log(prompt)

                  // Play Test
                  // console.log(process.env.VUE_APP_GOOGLE_AI_API_KEY);
                  const prompt_data = {contents: [{parts:[{text:prompt}]}]};
                  // console.log(JSON.stringify(prompt_data));
                  const url = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key='+process.env.VUE_APP_GOOGLE_AI_API_KEY
                  await fetch(url, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    // mode: "cors", // no-cors, *cors, same-origin
                    // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                      "Content-Type": "application/json",
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    // redirect: "follow", // manual, *follow, error
                    // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(prompt_data), // body data type must match "Content-Type" header
                  }).then(response => response.json())
                    .then(data => {
                      console.log('Response:', data);
                      // Handle the API response here
                      console.log(data.candidates[0].content.parts[0].text)
                      var content = data.candidates[0].content.parts[0].text
                      // Legacy splitter save
                      // const digit_check = /\d+/;
                      // const content_array = content.split(digit_check)
                      // const content_astrick = content.split('*')

                      // console.log('length '+content_array.length);
                      // if(content_array.length > 1){
                      //   console.log('long')
                      //   content = content_array.join("/n")
                      // }else if(content_astrick.length > 1){
                      //   console.log('not long')
                      //   content = content_astrick.join("/n")
                      // }
                      this.first_event_scene = content;
                    })
                    .catch(error => {
                      console.error('Error:', error);
                      // Handle errors here
                    });
                    // console.log('setFirstScene')
                    // console.log(result)
                  // return response.json(); // parses JSON response into native JavaScript objects
              },
              getObjectNames(arrayOfObjects) {
                let namesString = "";
                try{
                  for (let i = 0; i < arrayOfObjects.length; i++) {
                  const object = arrayOfObjects[i];
                  // Assuming each object has a 'name' property
                  namesString += object.name; 

                  // Add a comma if it's not the last element
                  if (i < arrayOfObjects.length - 1) {
                    namesString += ", ";
                  }
                }
              }catch{
                console.log('failed.')
              }
                

                return namesString;
              },
              async HelpGenerateCharacter() {
                this.loadingAll = true
                this.loadingCharacter = true
                this.loadingCompanion = true

                // Check settings to pass
                var settings = {
                  name: this.CharacterForm.name,
                  class: this.CharacterForm.class,
                  description: this.CharacterForm.description,
                  hates: this.CharacterForm.hates,
                  epic_motivation: this.CharacterForm.epic_motivation,
                  trivial_motivation: this.CharacterForm.trivial_motivation,
                  skills: this.CharacterForm.skills,
                }

                const result = await this.character(settings);

                // console.log(result);
                const goals = await this.goals(settings);
                // console.log(goals)
                this.characterGoals = goals
                console.log(this.characterGoals)

                this.CharacterForm.epic_motivation = result['epic_motivation'];
                this.CharacterForm.trivial_motivation = result['trivial_motivation'];
                this.CharacterForm.name = result['name'];
                this.CharacterForm.description = result['description'];
                this.CharacterForm.hates = result['hates'];
                this.CharacterForm.class = result['class'];
                this.CharacterForm.pateron = result['pateron'];
                this.CharacterForm.skills = this.getObjectNames(result['skills']);

                this.skills = result['skills'];
               this.loadingCharacter = false

                if(this.adventure && !this.adventure.name && !this.companionObject.name){
                  var companion_result = await this.companion(result);
                  var nemisis_result = await this.nemisis(result);
                  console.log(companion_result)
                  try{
                    companion_result['src'] = this.NextImage('random');
                  }catch{
                    console.log('Whoops no companion')
                  }
                  
                  try{
                    nemisis_result['src'] = this.NextImage('random');
                  }catch{
                    console.log('Whoops no Nemisis')
                  }
                  
                  console.log(companion_result)
                  this.companionObject = companion_result
                  this.nemisisObject = nemisis_result
                  
                }
                 if(typeof(this.adventure.goals) !='undefined' && this.adventure.goals != []){
                  this.characterGoals = this.adventure.goals
                }
                this.loadingCompanion = false
                this.loadingAll = false


              },
              buildSkills(str) {
                  // Split the string by commas
                  console.log()
                  if(typeof str =='string'){
                    const names = str.split(',');
                    // Create an object for each name
                    var skills = []
                   
                    for (let i = 0; i < names.length; i++) {
                      var object = {};
                      var name = names[i].trim(); // Remove any leading/trailing spaces
                      object['name'] = name; // Create an empty object with the name as the key
                      object['lore'] = 1; 
                      skills.push(object)
                    }
                    return skills;
                  }
                  return str
                 
                },
                async CreateCharacter() {
                this.loadingCharacter = true;
                this.loadingAll = true
                console.log('creating')
                console.log(this.CharacterForm.name)
                console.log(this.CharacterForm.name =='')
                 console.log(!this.companionObject )
                // If no companion Generate
                if(typeof this.CharacterForm.name =='undefined' || typeof this.companionObject == 'undefined'){
                  console.log('failed')
                  await this.HelpGenerateCharacter()
                }
                if(typeof this.nemisisObject == 'undefined'){
                  try{
                    var nemisis_result = await this.nemisis({'name':this.CharacterForm.name,'background':this.CharacterForm.motivation});
                    nemisis_result['src'] = this.NextImage('random');
                    this.nemisisObject = nemisis_result
                  }catch{
                    console.log('failed again!')
                  }

                }
                

                var name = this.CharacterForm.name || '';
                var description = this.CharacterForm.description || '';
                var epic_motivation = this.CharacterForm.epic_motivation || '';
                var skills = this.CharacterForm.skills || '';
                // console.log(skills)
                this.buildSkills(skills)
                var trivial_motivation = this.CharacterForm.trivial_motivation || '';
                var hates = this.CharacterForm.hates || '';
                var character_class = this.CharacterForm.class || '';
                var character_image_src = this.CharacterForm.src || '';
                var goals = this.characterGoals || []
                var encounters = []

                if(typeof(this.adventure.goals) !='undefined' && this.adventure.goals != []){
                  goals = this.adventure.goals
                }

                var items = [{'name':'The Diamond Heart','description':'A beautiful stone, that is said to have great power.','lore':1},{'name':'Enigma Amulet','description':'Allows the wearer to understand and speak all languages, including forgotten ones and animal/monster languages'}];
                var npcs = []
                if(this.companionObject && 'name' in this.companionObject){
                  // console.log('adding companion')
                  var companion = toRaw(this.companionObject)
                  companion['status'] = 4
                  companion['lore'] = 1
                  companion['relationship'] = 'companion'
                  // console.log(npcs)
                  // console.log(companion)
                  npcs.push(companion)
                  // console.log('npcs loaded')
                  // console.log(npcs)
                } else {
                  npcs = [{'name':'Rotgerius the Earl of Kryase','description':' Quiet and has Missing fingers, Particularly High Voice, protective of a valuable possession, Enjoys decadent pleasures','lore':1}];
                }
                if(this.nemisisObject && 'name' in this.nemisisObject){
                  var nemisis = toRaw(this.nemisisObject)
                  nemisis['relationship'] = 'nemisis'
                  npcs.push(nemisis)
                }
                
                var locations = [{'name':'Mecia','description':'Another dawn and another breath. The winds rises slowly out of the east as the sun crests over the Dread Lake. Ahead is the South Gate of Mecia, the ghost city. Once a thriving trade center it sits on the edge of the Shattered Lands. The High Order of Warlocks flags pickup the morning wind and their white background with a red circle with triangles to represent the sun.','lore':1}];
                var factions = [{'name':'The High Order of Warlocks','description':'The High Order of Warlocks have mastered a number of ancient magical arts that provide for safe transit through the Shattered Lands.','lore':1},{'name':'The Black Legion of Belmar','description':'A band of 200 from the Black Legion of Belmar, mercenaries are based in the northern part of the city of Mecia.','lore':1}];
                if(this.adventure.factions){
                  factions = factions.concat(toRaw(this.adventure.factions))
                  console.log('factions add')
                  console.log(toRaw(this.adventure.factions))
                  console.log(factions)
                }else{
                  console.log('no factions add')
                }
                // console.log('factions')
                // console.log(factions)
                var adventure_id = ''
                if(this.adventure){
                  console.log(this.adventure)
                  adventure_id = this.adventure.id
                }
               
                const result = await this.setFirstScene(name,epic_motivation)
                console.log(result)

                //scan all text with create to find items of note.
                var splice = epic_motivation+trivial_motivation+hates;
                splice = splice.split(' ');
                //removed ,index...
                splice.forEach(function (item) {
                // console.log(item, index);
                // console.log(item);
                var old_replace = item;
                // colore and shape not used
                //var color = ''
                // var shape =''
                var defined = item.split(/(?=[A-Z])/);
                var check = defined.join(" ")
                // console.log('here');
                // console.log(item)
                //alreadu defined
                item = check.replace(/([a-z])([A-Z])/g, '$1 $2');
                item = item.replace(/[^a-zA-Z $ @ # & %]/g, "");
                // console.log(item)
                // console.log(item);
                if(item.includes('@')){
                    epic_motivation = epic_motivation.replace(old_replace,item.replace('@',''));
                   locations.push({'name':item.replace('@','f'),'lore':1});
                }
                if(item.includes('$')){
                  epic_motivation =  epic_motivation.replace(old_replace,item.replace('$',''));
                  items.push({'name':item.replace('$',''),'lore':1})
                }
                if(item.includes('&&')){
                   epic_motivation =  epic_motivation.replace(old_replace,item.replace('&',''));
                   factions.push({'name':item.replace('&',''),'lore':1});
                }else if(item.includes('&')){
                   epic_motivation =  epic_motivation.replace(old_replace,item.replace('&',''));
                   npcs.push({'name':item.replace('&',''),'lore':1});
                }
                });
                // this.$router.push('/main')
                var uuid = this.$store.state.currentUser.uid

                var gameTime = this.$moment().toDate();
                // var createdOn = new Date();
                // need to add?
                // var next_event = {gameTime: gameTime,createdOn: createdOn, description:'Here is the next thing to happen'}
                console.log('here')
                var encounter_settings = {'gameTime':gameTime,'type':'start'}
                var character_playing = 'general'


                if(this.playTest !=''){
                  encounter_settings['type'] = this.playTest
                  character_playing = 'playtest'
                }
                if(this.adventure && !this.adventure.name){
                  character_playing = 'adventure'
                }
                console.log(encounter_settings)
                var first_event = this.generate_encounters(encounter_settings);
                console.log('first event')
                console.log(first_event)
                first_event['description'] = this.first_event_scene || '';
                var events = [first_event]
                if(typeof(this.adventure.events) !='undefined' && this.adventure.events != []){
                  events = [this.adventure.events[0]]
                  encounters = events.slice(1) || [];
                  console.log('setting event for adventure')
                }

                
                var new_character = {
                      createdOn: new Date(),
                      name: name,
                      class:character_class,
                      description: description,
                      epic_motivation: epic_motivation,
                      trivial_motivation: trivial_motivation,
                      items:items,
                      npcs:npcs,
                      factions:factions,
                      locations:locations,
                      hates: hates,
                      userId: uuid,
                      location:'Mecia',
                      location_id: 'OMSgLb55Kj8vj8OrhWJ5',
                      quest:epic_motivation,
                      quests:[{'name':'Staring in Mecia','location_id':'krAgaDrvDTjVR1z8ZMrh'}],
                      src: character_image_src,
                      events:events,
                      character_playing: character_playing,
                      adventure_id:adventure_id || '',
                      skills: this.skills || [],
                      goals: goals,
                      creatures: [],
                      encounters: encounters

                  }
                  console.log(new_character)
                fb.charactersCollection.add(new_character).then(ref => {
                      console.log('here');
                      console.log(ref);
                      this.$router.push('/character/'+ref.id)
                  }).catch(err => {
                      console.log(err)
                  })
                
              }
          }
        }
</script>