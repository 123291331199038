<template>
    <div id="invite">
        <div >
            <div class="col-12">Invites</div>
            <hr>
            <div class="col-12">
                <table class="table" :class="showType" style="font-size:12px;">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Email</th>
                  <th scope="col">Play Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="invite in invites_list" v-bind:key='invite' class="" style="font-weight: 100;">
                    <td  v-if="showType=='user' && invite.status == 'accepted'">
                      <router-link v-if="invite.adventureId && invite.adventureId !='' "  :to="'adventure/'+invite.adventureId" style="color:white; text-decoration:none;">
                        <button class="btn btn-sm btn-secondary">Play</button></router-link>
                      <router-link v-else  :to="'character/'+invite.characterId+'?gm=true'" style="color:white; text-decoration:none;">
                        <button class="btn btn-sm btn-secondary">Play</button></router-link>

                      </td>
                    <td v-else-if="email != invite.email || invite.status == 'accepted'"> {{invite.status || 'Sent'}}</td>
                    <td v-else><button class="btn btn-sm btn-primary" @click.prevent="acceptInvite(invite)">Accept</button></td>
                    
                    <td>{{invite.email}}</td>
                    <td >{{invite.playType || 'general'}}</td>
                    
                </tr>
            </tbody>
                </table>
            </div>
        </div>
        </div>
</template>

<script>
    const fb = require('../firebaseConfig.js')
    export default {
        props: {
          query: {
            type: String,
            default: () => ''
          },
          showType: {
            type: String,
            default: () => ''
          }
        },
         data() {
            return {
                invites_list: [],
                errorMsg: '',
                email: this.$store.state.currentUser.email || ''
            }

    },
     beforeMount(){
      this.loadInvites()
    },
    methods: {
    follow(invite){
    var follows = []
      if(this.$store.state.userProfile){
        follows = this.$store.state.userProfile.characterFollows || []
      }
      
      if(follows.includes(invite.characterId)){
        alert('Already Following!')
        return true
      }
      console.log('updating characterFollows')
      // console.log(this.$store.state.userProfile.characterFollows)
      follows.push(invite.characterId)
      // console.log(follows)
      // Probably need a user for user settings
      var userId = this.$store.state.currentUser.uid;
      fb.usersCollection.doc(userId).update({
          characterFollows:follows,
        }).then(ref => {
          console.log('updated')
          console.log(ref)
          
        }).catch(err => {
          console.log(err)
           fb.usersCollection.doc(userId).set({
            characterFollows:follows,
          })
           console.log('created')
        })
    },
    acceptInvite(invite){
        // console.log(invite)
        this.follow(invite)
        fb.invitesCollection.doc(invite.id).update({status:'accepted'}).then(ref => {
          console.log(ref)
        }).catch(err => {
          console.log(err)
        });
    },
    loadInvites: function(){
      console.log('show')
      console.log(this.showType)
      var uid = this.query;
      console.log(this.query);
      // console.log(this.query)
      // .where('characterId','==',uid)
      if(this.showType =='user'){
        var email = this.$store.state.currentUser.email;
        fb.invitesCollection.where('email','==',email).orderBy('createdOn', 'desc').onSnapshot(querySnapshot => {
              let invitesArray = []

              querySnapshot.forEach(doc => {
                  let invite= doc.data()
                  invite.id = doc.id
                  invitesArray.push(invite)
              })
              this.invites_list  = invitesArray;
      })
        return
      }
       if(this.showType =='adventure'){
        console.log('adventure')
        fb.invitesCollection.where('adventureId','==',uid).orderBy('createdOn', 'desc').onSnapshot(querySnapshot => {
              let invitesArray = []

              querySnapshot.forEach(doc => {
                  let invite= doc.data()
                  invite.id = doc.id
                  invitesArray.push(invite)
              })
              this.invites_list  = invitesArray;
              console.log(invitesArray)
      })
        return
      }
      
      fb.invitesCollection.where('characterId','==',uid).orderBy('createdOn', 'desc').onSnapshot(querySnapshot => {
              let invitesArray = []

              querySnapshot.forEach(doc => {
                  let invite= doc.data()
                  invite.id = doc.id
                  invitesArray.push(invite)
              })
              this.invites_list  = invitesArray;
      })
    }
  }
        }
</script>
