<template>
  <div class="character_section">
    <div class="row justify-content-md-center " style='margin-bottom: 8px;'>
     <nav class="navbar-fixed-top navbar fixed-top navbar-expand-lg navbar-top justify-content-center" >
      <router-link class="navbar-brand" to="/main" style="font-size: 14px;">
    <div class='pocket-brand-wrap ' style="width:20px; height:20px;  float:left; border-radius: 3px; margin-right:4px; ">
      <div style=" padding:1px;"><i class="ra ra-perspective-dice-random" style="font-size: 14px;"></i></div></div>
    Pocket RPG
  </router-link>
    </nav>
      </div>
    <div class="row justify-content-md-center " style='margin-bottom: 8px;'>
      <div class="col-xs-12" style='margin-bottom: 8px;'>
    <h1 class="outside-white" style="margin-top:60px; color:black;" >Create Adventure</h1>
  </div>
</div>

<div class="row justify-content-md-center history">
  <div class='col-xs-12 col-md-6'>
    <div class="card character-card">
      <img v-if="adventure && adventure.image_src" class="" style="!position: absolute; height:600px;" :src="adventure.image_src" alt="Card image cap">
      <img v-else class="card-image-marquee-create" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Dha_port_city.jpeg" alt="Card image cap">
      <div class="card-body" style="    background: rgba(0, 0, 0, .5); color: white;">
        <h5 v-if="adventure && adventure.title">{{adventure.title}}</h5>
        <!-- <h5 v-else class="card-title">Our hero</h5> -->
        <p v-if="adventure" class="card-text" style="font-size: 14px; text-align: left;" v-html="adventure.description"></p>
        <!-- <p v-if="adventure" class="card-text" style="font-size: 14px; text-align: left;">{{adventure.description}}</p> -->
        <p v-else class="card-text">Campaign: Shattered Lands <a v-if='!loadingCharacter' href="#" class="" @click.prevent="toggleCampaignInfo()" >more info</a></p>
         <div v-if="adventure && adventure.description" class="outgoing_msg"><h6>Adventure Goals</h6>
            <div>
            <div v-for="(goal) in adventure.goals" v-bind:key='goal' >
              <i :class="'ra '+tagLogo(goal.type)"></i> {{goal.description}}
          </div>
        </div>
          </div>
          <div v-else class="outgoing_msg"><h6>Adventure Goals</h6>
            <div>
            <div v-for="(goal) in characterGoals" v-bind:key='goal' >
              <i :class="'ra '+tagLogo(goal.type)"></i> {{goal.description}}
          </div>
        </div>
          </div>
        <form @submit.prevent class='clearfix'>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
          </div>
           <label class="label-helper" for="name">Title</label>
          <input id="title" type="text" v-model.trim="AdventureForm.title" class="form-control" placeholder="Title" aria-label="Character" aria-describedby="basic-addon1">
        </div>
         
        <div class="input-group mb-3">
         
             <label class="label-helper" for="description">Description</label>
          <textarea class="form-control larger" id="description" v-model.trim="AdventureForm.description" aria-label="With textarea" placeholder="In the heart of"></textarea>
        </div>
        <!-- <div class="banter-tags" style="clear:both; background:white;">Hooks<div class="banter-tags" v-html="motivationTags"></div></div> -->
        <div class="input-group mb-3">
          <label class="label-helper" for="goals">Goals</label>
          <textarea id='goals' class="form-control larger" v-model.trim="AdventureForm.goals" v-on:keyup="checkInfo" aria-label="With textarea" placeholder="Collect the magic item, help Vram pay his debts off, attack a monster."></textarea>

        </div>
        <div class="input-group mb-3">
          <label class="label-helper" for="skills">Factions</label>
          <textarea id='factions' class="form-control larger" v-model.trim="AdventureForm.factions" v-on:keyup="checkInfo" aria-label="With textarea" placeholder="The Sea guild, a hated group."></textarea>

        </div>
        <div class="input-group mb-3">
          
          <label class="label-helper" for="trivial_motivation">Companion</label>
          <textarea id='trivial_motivation' class="form-control larger" v-model.trim="AdventureForm.trivial_motivation" v-on:keyup="checkInfo"  aria-label="With textarea" placeholder="Vram a happy go lucky paladin."></textarea>
        </div>
        <div class="input-group mb-3">
          <label class="label-helper" for="hate">Nemisis</label>
          <textarea id='nemisis' class="form-control larger" v-model.trim="AdventureForm.nemisis" aria-label="With textarea" v-on:keyup="checkInfo"  placeholder="A dark wizard named lardal."></textarea>
        </div>
        <div>
          <div v-if='loadingCompanion'>
         Loading Companion & Nemisis
         <div  class="spinner-border text-success" role="status">
         
            <span class="sr-only"><i class="ra  ra-helmet true"></i></span>
          </div><br>
        </div>
        <div v-else-if="companionObject.name">
          <h6>Companion</h6> 
          <div class="clearfix"><div class="small-image float-start"><img v-if="companionObject.src" class=""  :src="companionObject.src" :style="characterImage(companionObject.src)">
                  <img v-else  class="small-image float-left" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
           </div><div style="float:left;">{{companionObject.name}} the {{companionObject.class}}</div> </div>
          <br>
          <div v-if="nemisisObject && nemisisObject.name">
          <h6>Nemisis</h6>
          <div class="clearfix"><div class="small-image float-start" ><img v-if="nemisisObject.src" class=""  :src="nemisisObject.src" :style="characterImage(nemisisObject.src)">
                  <img v-else  class="small-image float-left" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
                   </div><div style="float:left;">{{nemisisObject.name}} the {{nemisisObject.class}}</div></div>
        </div>
        <br>
      </div>

        </div>
        <div v-if='loadingCharacter'>
         Building Adventure
         <div  class="spinner-border text-success" role="status">
         
            <span class="sr-only"><i class="ra  ra-helmet true"></i></span>
          </div><br>
        </div>
        <button v-if='!loadingCharacter' :disabled="loadingAll" href="#" class="btn btn-primary" @click.prevent="CreateAdventure" style="margin-left:10px;">Create Adventure</button>
      </form>
      </div>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
   //removed
   // import { mapState } from 'vuex'
    const fb = require('../../firebaseConfig.js');

    // const encounters = require('../../helpers/encounters.js');
    import {generate_encounters} from  '../../helpers/encounters.js'
    import {goals,character, companion, nemisis,factions} from '../../helpers/generator.js'
    import { toRaw } from 'vue'
    export default {
         data() {
            return {
                showScenario: false,
                loadingCharacter: false,
                loadingCompanion:false,
                loadingAll: false,
                companionObject: {},
                nemisisObject: {},
                adventure: {},
                playTest: '',
                imageIndex:0,
                skills: [],
                characterGoals: [],
                AdventureForm: {
                    src:'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=157.328125&y=10.203125&width=421.65625&height=240.9375',
                    items:[],
                    npcs:[],
                    locations:[],
                    factions: [],
                }
              }
            },
            beforeMount(){
              console.log()
              if(this.$route.query.adventure){
                this.loadAdventure(this.$route.query.adventure)
              }
              if(this.$route.query.playtest){
                this.playTest = this.$route.query.playtest;
                console.log(this.playTest)
              }
              },
              methods: {
                character,
                companion,
                nemisis,
                generate_encounters,
                goals,
                factions,
     tagLogo(type){
      var logos = {
        'item':'ra-torch',
        'npc':'ra-helmet',
        'heart':'ra-two-hearts',
        'rumor':'ra-x-mark',
        'faction':'ra-crown',
        'creature':'ra-hydra',
        'info':'ra-book',
      }
      return logos[type]
    },
    loadAdventure: function(adventureID){
      console.log('loading adventure '+adventureID)
      fb.adventuresCollection.doc(adventureID).onSnapshot(snapshot=>{
        if (snapshot.exists) {
          // console.log('snapshot')
          let adventure = snapshot.data();
          adventure.id = snapshot.id;
          this.adventure = adventure 
          // console.log(adventure)
          if(this.adventure.companion){
            this.companionObject = this.adventure.companion
          }
          if(this.adventure.nemisis){
            this.nemisisObject = this.adventure.nemisis
          }
          console.log(this.adventure)
        } else {
                // snapshot.data() will be undefined in this case
                console.log("No such document!");
              }  
            })
    },
              getObjectNames(arrayOfObjects) {
                let namesString = "";
                try{
                  for (let i = 0; i < arrayOfObjects.length; i++) {
                  const object = arrayOfObjects[i];
                  // Assuming each object has a 'name' property
                  namesString += object.name; 

                  // Add a comma if it's not the last element
                  if (i < arrayOfObjects.length - 1) {
                    namesString += ", ";
                  }
                }
              }catch{
                console.log('failed.')
              }
                

                return namesString;
              },
                async CreateAdventure() {
                this.loadingCharacter = true;
                this.loadingAll = true
                console.log('creating')
                var title = this.AdventureForm.title || '';
                var description = this.AdventureForm.description || '';
                var factions_details = this.AdventureForm.factions || '';
                var faction_list = await this.factions({'faction':factions_details,'context':description});
                var goals = this.AdventureForm.goals || '';
                goals = await this.goals({'goals':goals,'context':description})
               
                
                var image_src = 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Dha_port_city.jpeg'
                var items = [{'name':'The Diamond Heart','description':'A beautiful stone, that is said to have great power.','lore':1},{'name':'Enigma Amulet','description':'Allows the wearer to understand and speak all languages, including forgotten ones and animal/monster languages'}];
                var npcs = []
                var companion = this.companionObject ||{};
                if(this.nemisisObject && 'name' in this.nemisisObject){
                  var nemisis = toRaw(this.nemisisObject)
                  nemisis['relationship'] = 'nemisis'
                  npcs.push(nemisis)
                }
                
                var locations = [{'name':'Mecia','description':'Another dawn and another breath. The winds rises slowly out of the east as the sun crests over the Dread Lake. Ahead is the South Gate of Mecia, the ghost city. Once a thriving trade center it sits on the edge of the Shattered Lands. The High Order of Warlocks flags pickup the morning wind and their white background with a red circle with triangles to represent the sun.','lore':1}];
                
                

                var uuid = this.$store.state.currentUser.uid
                var new_adventure = {
                      createdOn: new Date(),
                      title:title,
                      description:description,
                      goals:goals,
                      factions:faction_list,
                      locations:locations,
                      location:locations[0],
                      creatorId: uuid,
                      companion: companion,
                      items:items,
                      image_src:image_src
                  }
                  console.log(new_adventure)
                fb.adventuresCollection.add(new_adventure).then(ref => {
                      console.log('here');
                      console.log(ref);
                      this.$router.push('/adventure/'+ref.id)
                  }).catch(err => {
                      console.log(err)
                  })
                
              }
          }
        }
</script>