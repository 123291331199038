<template>
  <div class="character_section">
<div class="row justify-content-md-center history">
  <div class='col-xs-12 col-md-6'>
    <div class="card character-card">
      <img v-if="event && event.image_src" class="" style="!position: absolute; height:600px;" :src="event.image_src" alt="Card image cap">
      <img v-else class="card-image-marquee-create" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Dha_port_city.jpeg" alt="Card image cap">
      <div class="card-body" style="    background: rgba(0, 0, 0, .5); color: white;">
        <h5 v-if="event && event.title">{{event.title}}</h5>
        <!-- <h5 v-else class="card-title">Our hero</h5> -->
        <p v-if="event" class="card-text" style="font-size: 14px; text-align: left;" v-html="event.description"></p>
        <!-- <p v-if="event" class="card-text" style="font-size: 14px; text-align: left;">{{event.description}}</p> -->
        <p v-else class="card-text">Fill in</p>
         <div v-if="event && event.description" class="outgoing_msg"><h6>Event</h6>
            <div>
            <div v-for="(goal) in event.goals" v-bind:key='goal' >
              <i :class="'ra '+tagLogo(goal.type)"></i> {{goal.description}}
          </div>
        </div>
          </div>
          <div v-else class="outgoing_msg"><h6>Event Goals</h6>
            <div>
            <div v-for="(goal) in characterGoals" v-bind:key='goal' >
              <i :class="'ra '+tagLogo(goal.type)"></i> {{goal.description}}
          </div>
        </div>
          </div>
        <form @submit.prevent class='clearfix'>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
          </div>
           <label class="label-helper" for="name">Title</label>
          <input id="title" type="text" v-model.trim="EventForm.title" class="form-control" placeholder="Title" aria-label="Character" aria-describedby="basic-addon1">
        </div>
        <div class="input-group mb-3">
        <select class="form-select" aria-label="Default select example" v-model.trim="EventForm.type">
          <option selected>Select Event Type</option>
          <option value="social">Social</option>
          <option value="combat">Combat</option>
          <option value="creature">Creature</option>
          <option value="exploration">Exploration</option>
        </select>
        </div>
         
        <div class="input-group mb-3">
         
             <label class="label-helper" for="description">Description</label>
          <textarea class="form-control larger" id="description" v-model.trim="EventForm.description" aria-label="With textarea" placeholder="In the heart of"></textarea>
        </div>
        <!-- <div class="banter-tags" style="clear:both; background:white;">Hooks<div class="banter-tags" v-html="motivationTags"></div></div> -->
        <div class="input-group mb-3">
          <label class="label-helper" for="goals">Goals</label>
          <textarea id='goals' class="form-control larger" v-model.trim="EventForm.goals" v-on:keyup="checkInfo" aria-label="With textarea" placeholder="Collect the magic item, help Vram pay his debts off, attack a monster."></textarea>

        </div>
        <div class="input-group mb-3">
          <label class="label-helper" for="skills">Factions</label>
          <textarea id='factions' class="form-control larger" v-model.trim="EventForm.factions" v-on:keyup="checkInfo" aria-label="With textarea" placeholder="The Sea guild, a hated group."></textarea>

        </div>
       <!--  <div class="input-group mb-3">
          
          <label class="label-helper" for="trivial_motivation">Companion</label>
          <textarea id='trivial_motivation' class="form-control larger" v-model.trim="EventForm.trivial_motivation" v-on:keyup="checkInfo"  aria-label="With textarea" placeholder="Vram a happy go lucky paladin."></textarea>
        </div> -->
        <!-- <div class="input-group mb-3">
          <label class="label-helper" for="hate">Nemisis</label>
          <textarea id='nemisis' class="form-control larger" v-model.trim="EventForm.nemisis" aria-label="With textarea" v-on:keyup="checkInfo"  placeholder="A dark wizard named lardal."></textarea>
        </div> -->
        <div>
          <div v-if='loadingCompanion'>
         Loading Companion & Nemisis
         <div  class="spinner-border text-success" role="status">
            <span class="sr-only"><i class="ra  ra-helmet true"></i></span>
          </div><br>
        </div>
        <div v-else-if="companionObject.name">
          <h6>Companion</h6> 
          <div class="clearfix"><div class="small-image float-start"><img v-if="companionObject.src" class=""  :src="companionObject.src" :style="characterImage(companionObject.src)">
                  <img v-else  class="small-image float-left" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
           </div><div style="float:left;">{{companionObject.name}} the {{companionObject.class}}</div> </div>
          <br>
          <div v-if="nemisisObject && nemisisObject.name">
          <h6>Nemisis</h6>
          <div class="clearfix"><div class="small-image float-start" ><img v-if="nemisisObject.src" class=""  :src="nemisisObject.src" :style="characterImage(nemisisObject.src)">
                  <img v-else  class="small-image float-left" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
                   </div><div style="float:left;">{{nemisisObject.name}} the {{nemisisObject.class}}</div></div>
        </div>
        <br>
      </div>
        </div>
        <div v-if='loadingCharacter'>
         Building Event
         <div  class="spinner-border text-success" role="status">
         
            <span class="sr-only"><i class="ra  ra-helmet true"></i></span>
          </div><br>
        </div>
        <button v-if='!loadingCharacter' :disabled="loadingAll" href="#" class="btn btn-primary" @click.prevent="CreateEvent" style="margin-left:10px;">Create Event</button>
      </form>
      </div>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
    const fb = require('../../firebaseConfig.js');
    import {generate_encounters} from  '../../helpers/encounters.js'
    import {goals,character, companion, nemisis,factions} from '../../helpers/generator.js'
    import { toRaw } from 'vue'
    export default {
      props: {
          query: {
            type: String,
            default: () => ''
          },
          adventure: {
            type: Object,
            default: () => {}
          },
           currentCharacter: {
            type: Object,
            default: () => {}
          },
          eventType: {
            type: String,
            default: () => ''
          }
        },
         data() {
            return {
                showScenario: false,
                loadingCharacter: false,
                loadingCompanion:false,
                loadingAll: false,
                companionObject: {},
                nemisisObject: {},
                playTest: '',
                imageIndex:0,
                skills: [],
                characterGoals: [],
                event: {},
                EventForm: {
                    src:'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x=157.328125&y=10.203125&width=421.65625&height=240.9375',
                    items:[],
                    npcs:[],
                    locations:[],
                    factions: [],
                    type: ''
                }
              }
            },
            beforeMount(){
              },
              methods: {
                character,
                companion,
                nemisis,
                generate_encounters,
                goals,
                factions,
     tagLogo(type){
      var logos = {
        'item':'ra-torch',
        'npc':'ra-helmet',
        'heart':'ra-two-hearts',
        'rumor':'ra-x-mark',
        'faction':'ra-crown',
        'creature':'ra-hydra',
        'info':'ra-book',
      }
      return logos[type]
    },
              getObjectNames(arrayOfObjects) {
                let namesString = "";
                try{
                  for (let i = 0; i < arrayOfObjects.length; i++) {
                  const object = arrayOfObjects[i];
                  // Assuming each object has a 'name' property
                  namesString += object.name; 

                  // Add a comma if it's not the last element
                  if (i < arrayOfObjects.length - 1) {
                    namesString += ", ";
                  }
                }
              }catch{
                console.log('failed.')
              }
                

                return namesString;
              },
              async createEventInvite(playerId,characterId){
                console.log('event invite')
  
                var userID = this.$store.state.currentUser.uid;
                var invite = {
                    createdOn: new Date(),
                    creatorId: userID,
                    type: 'event',
                    characterId: characterId,
                    email: '',
                    playerId:playerId
                }
                console.log(invite);
                await fb.invitesCollection.add(invite).then(ref => {
                  console.log(ref)
                  this.errorMsg = 'Success Invite to Event Created!'
                  this.$emit('toggleInvite');
                  console.log('toggle')
                }).catch(err => {
                  console.log(err)
                  this.errorMsg = 'Failed Try again.'
                });
                },
                async CreateEvent() {
                this.loadingCharacter = true;
                this.loadingAll = true
                console.log('creating')
                var title = this.EventForm.title || '';
                var description = this.EventForm.description || '';
                var factions_details = this.EventForm.factions || '';
                var event_type = this.EventForm.type || '';
                var faction_list = []
                if(factions_details != ''){
                  var faction = await this.factions({'faction':factions_details,'context':description});
                  faction_list.push(faction)
                }
                
                var goals = this.EventForm.goals || '';
                goals = await this.goals({'goals':goals,'context':description})
               var goal = goals[0].description || 'Whhaa whaaa not set'
               
                
                var image_src = 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Dha_port_city.jpeg'
                var items = [{'name':'The Diamond Heart','description':'A beautiful stone, that is said to have great power.','lore':1},{'name':'Enigma Amulet','description':'Allows the wearer to understand and speak all languages, including forgotten ones and animal/monster languages'}];
                var npcs = []
                var companion = this.companionObject ||{};
                if(this.nemisisObject && 'name' in this.nemisisObject){
                  var nemisis = toRaw(this.nemisisObject)
                  nemisis['relationship'] = 'nemisis'
                  npcs.push(nemisis)
                }
                
                var locations = [{'name':'Mecia','description':'Another dawn and another breath. The winds rises slowly out of the east as the sun crests over the Dread Lake. Ahead is the South Gate of Mecia, the ghost city. Once a thriving trade center it sits on the edge of the Shattered Lands. The High Order of Warlocks flags pickup the morning wind and their white background with a red circle with triangles to represent the sun.','lore':1}];
                

                var uuid = this.$store.state.currentUser.uid
                
                var new_event = {
                      createdOn: new Date(),
                      title:title,
                      description:description,
                      goals:goals,
                      factions:faction_list,
                      locations:locations,
                      location:locations[0],
                      creatorId: uuid,
                      companion: companion,
                      items:items,
                      image_src:image_src,
                      twist: '',
                      type:event_type,
                      rumor: description,
                      goal: goal || '',
                      HowItsTiedToMotivation: ''
                  }
                  
                
                // Update Character Events
                console.log(this.eventType)
                if(this.eventType =='character'){
                  console.log('update Character')
                  var encounters = this.currentCharacter.encounters || [];
                  console.log(encounters)
                  encounters.push(new_event)
                  console.log(new_event)
                  console.log(encounters)
                  console.log(this.currentCharacter.id);
                  fb.charactersCollection.doc(this.currentCharacter.id).update({encounters:encounters}).then(ref => {
                      console.log('close');
                      console.log(ref)
                      this.$emit('toggleCreateEvent');
                      var playerId = this.currentCharacter.userId
                      console.log(this.currentCharacter.userId)
                      this.createEventInvite(playerId, this.currentCharacter.id);
                  }).catch(err => {
                      console.log(err)
                  })
               return 
              }
              // For Adventure apoend to Adventure Event
              var events = this.adventure.events || [];
              console.log(events)
              events.push(new_event)
              console.log(new_event)
                
                fb.adventuresCollection.doc(this.adventure.id).update({events:events}).then(ref => {
                      console.log('close');
                      console.log(ref)
                      this.$emit('toggleCreateEvent');
                  }).catch(err => {
                      console.log(err)
                  })
                
              }
          }
        }
</script>