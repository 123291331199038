<template>
    <div class="col-xs-12 col-md-6" style="margin-bottom: 60px;">
        <div class='card'>

          <dl class="row ">
            <dd class="col-sm-12 " style="text-align: left;">
              <div class="p-3 time_date black">
                <h4>Instructions</h4>
                <span><a href='https://docs.google.com/document/d/1f9xXMIlSLeSPJHiQGPmHu4VBr23OPwbZgDPvNC5RUuo/edit?usp=sharing' target="_blank">Detailed Instructions</a></span>
                <h6>Getting Started: Towns and Cities <i class="ra  ra-castle-emblem"></i></h6>
                <hr/>
                 You start out in a town or city. Search for <b>Rumors</b> from the locals for potential quests to show your worth. Each <b>Card</b> is a scene in your story. You can add to it by clicking on <b>Add to Scene</b>.  Read the last text and respond with what your character might do. Here are a few examples.
                 <br>
                 <br>
                 Ask for information.
                 <span class="time_date">"Billy, asks the rouge to tell me more about the Dark Castle."</span>
                 <span class="time_date">"Whats your name stranger?, I would like to buy you a drink."</span>
                 <span class="time_date">"Where can I find the Red Wizard? I hope he doesn't hav protection."</span>
                 <br>
                 Instructure your companion
                 <span class="time_date">"Billy, asks his companion to go attack the guards."</span>
                  <br>
                 Take an action
                 <span class="time_date">"Billy, trys to shoot a fireball at the creature."</span>
                 <span class="time_date">"Bill enters the Tavern."</span>
                 <br>
                 To navigate to <b>current</b> or past scenes click on the related <b>current</b>, or <b>history</b> buttons in the bottom nav. 
                <!-- If <b><i class="ra  ra-helmet"></i> Dungeon Buddy</b> is on, <b>Rumors</b> will be generated for you. If Dungeon buddy is off, you need a friend to make a rumor or two. -->
                <br>
                <br>
                <h6>Exploring: Pick a Rumor <i class="ra  ra-x-mark"></i></h6>
                <hr/>
                If a <b>text</b> block has been generate that is something you would like to explore, click on it. A menu will appear, click on the <b>Generate Rumor</b> button. This will create a rumor you can explore. 

                <br><br>
                After you are done and want to move on, click <b>End Scene</b> at which point there will be a success or fail roll. In the <b>Quest</b> section click on the <b>Rumor</b> your want to pursue and when the current scene is over a <b>Card</b> will be generated for the next scene with that rumor. Be wary though, as you venture out of the towns and cities and continue on your journey things will become more difficult.
                <br>
                <br>
                <h6>Growing the World <i class="ra  ra-compass"></i></h6>
                <hr/>
                <span class="time_date">"Tell me more about the Dark Castle."</span>
                <span>Click on a <b>text</b> in a scene, this will give you a text menu. You can select a Noun below the message with <b>Generate Select Name</b> then click the Generate Select Action you want to take. This will create a related item in your quest log that you can go review. 
                <br><br>
                Its up to you to progress the game, but the more you ask questions about existing rumors, factions, items and NPCs the better off or more informed your character is for the end of the scene. You can also add things to the world, such as NPCs, Items, Locations, and such.</span>
                <br>
                
                <br>
                <!-- <img style="width:100%; margin:20px 0" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Screenshot%202024-02-09%20at%205.04.41%20PM.png"> -->
                <h6>Doom <i class="ra ra-player-thunder-struck"></i> and Lore <i class="ra ra-book"></i></h6>
                <hr/>
                <span >Everytime you add to the scene your <b>Doom</b> goes up making it harder. But at the same time if you discover secrets or take a great action your <b>Lore</b> goes up helping you.</span>
                <br>
                <br>
                <h6>The End <i class="ra  ra-player-shot"></i></h6>
                <hr/>
                <span >Your character needs closure, it could be in defeating a creature, finding a missing scroll, or whatever your main quest is. Theres always lucked invovled and remember the more you take on the more challenging things become.</span>
                <br>
                <br>
                <h6> Tips</h6>
                <hr>
                Try and recruit an ally, get an item, or gain more info on the rumors while you are in town. You get a few attempts in a town to influence things in your favor. Once your done in town you can click end scene to choose where to go next. 

            </div>
        </dd>
    </dl>
</div>
</div>
</template>