<template>
  <div class="col-xs-12 col-md-4 card" style="margin-bottom: 60px;">
        <h4 style="margin-top: 20px;">
          <!-- <img v-if="item.src" class="small-image" :src="item.src"> -->
        <img class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered%20Lands%20Items.png">
        Rumor - {{rumor.type || ''}}
      </h4>
    <div class="item-card">
      
      <div class="card-body card-info">
        <div>
        <br>
        <h6>Goal </h6><hr>
        <div style="text-align: left;">{{rumor.goal || ''}} </div>
        <br>
        <h6>Description </h6><hr>
        <div style="text-align: left;">{{rumor.rumor || ''}} </div>
        <br>
        <h6>Twist </h6><hr>
        <div v-if="$route.query.gm" style="text-align: left;">{{rumor.twist || ''}} </div>
        <div  v-else style="text-align: left;">Only for your freinds. : ) </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
   //removed
    export default {
       props: {
          rumor: {
            type: Object,
            default: () => {}
          }
        },
         data() {
            return {
                updatingitem: false,
                showMarquee: false
              }
            },
              methods: {
      }
    }
</script>