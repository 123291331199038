//Encounters
// Creature
// 

// function combat_levels(){
// 	var combat_levels_list = {
// 		'troublesome': {'style':'Not dangerous but'},
// 		'dangerous': {'style':'equally matched'},
// 		'formidable': {'style':'Exceptional or Mighty'},
// 		'extreme': {'style':'Overwhelming Skill or power'},
// 		'epic':{'style':'Legenday with mythic power'}
// 	}
// }

function combat_tactics(settings){
  var tactics = [
    "Area Denial",
    "Summon Minions",
    "Disorienting Attacks",
    "Deception",
    "Elemental Manipulation",
    "Status Effects",
    "Counterattacks",
    "Regeneration",
    "Teleportation",
    "Buffing Allies",
    "Terrain Manipulation",
    "Mind Control",
    "Elemental Shields",
    "Evasion",
    "Trickery",
    "Powerful Finishers",
    "Aerial Assaults",
    "Terrain Advantage",
    "Temporal Manipulation",
    "Desperation Mode"
  ]
  if('single' in settings){
    return tactics[Math.floor(Math.random() * tactics.length)];
  }
  return tactics
 }

function combat_styles(){
	var styles = ['tactical','trickery','forceful','use a partner','courage','terrain','speed','range'];
	return styles
}

function generate_encounters (settings) {
		//Auto generate if not passed
        console.log('generate')
        console.log(settings)
		var encounter_types = ['creature','location','trap'];
		var choose = Math.floor(Math.random() * encounter_types.length);
		// var gameTime = 0;
		if(typeof(settings) !== 'undefined' && typeof(settings.gameTime) === 'undefined'){
			settings.gameTime = 0;
		}
		if(typeof(settings)==='undefined'){
			settings = {'type':encounter_types[choose]}
		}else if(typeof(settings.type)==='undefined'){
			settings['type'] = encounter_types[choose];
		}
	if (settings.type =='creature'){
		// var creature_lookup = '';
		// var creature_id = 'insert_here';
		return {type:'creature',gameTime: settings.gameTime,createdOn: new Date(), description:'As you approach you hear the sound of a creature snarling behind you...'}
	}
	if (settings.type =='exploration'){
		// Fetch location
		return {type:'exploration',
		gameTime: settings.gameTime,
		createdOn: new Date(), 
		description:'The day began like any other...',
		prompt: 'Exploration encounters involve discovering new areas of the game world. These encounters can be triggered by finding a hidden passage, solving a puzzle, or defeating a monster. Exploration encounters can lead to new quests, treasure, or simply a greater understanding of the game world.'}
	}
	if (settings.type =='puzzle'){
		// Fetch location
		return {type:'exploration',
		gameTime: settings.gameTime,
		createdOn: new Date(), 
		description:'The day began like any other...',
		prompt: 'Puzzle encounters involve solving a riddle or completing a puzzle in order to proceed. These encounters can be a fun and challenging way to break up the monotony of combat and exploration. Puzzle encounters can also be used to introduce new game mechanics or concepts to the players.'}
	}
	if (settings.type =='start'){
		// Fetch location
		return {
			type:'start',
			gameTime: settings.gameTime,
            createdOn: new Date(), 
			description:'Another dawn and another breath. The winds rises slowly out of the east as the sun crests over the Dread Lake. Ahead is the South Gate of Mecia, the ghost city. Once a thriving trade center it sits on the edge of the Shattered Lands. The High Order of Warlocks flags pickup the morning wind and their white background with a red circle with triangles to represent the sun.',
			prompt: 'Starting encounter is to help the player find a calling or quest. This happens by generating rumors.'
		}
	}
	if (settings.type =='social'){
		// Fetch location
		return {type:'exploration',
		gameTime: settings.gameTime,
		createdOn: new Date(), 
		description:'The day began like any other...',
		prompt: ' Social encounters involve interacting with non-player characters (NPCs) in the game world. These encounters can be used to gather information, gain quests, or simply role-play and develop the characters relationships with the NPCs. Social encounters can be just as important as combat encounters, as they can help to flesh out the game world and make it feel more immersive'}
	}
	if (settings.type =='combat'){
		// Fetch location
		return {
            type:'combat',
            gameTime: settings.gameTime,
            createdOn: new Date(), 
            description:'Straightforward fight against enemies. Could be a random encounter, a planned ambush, or the culmination of a quest.'
        }
	}
	if (settings.type =='boss'){
		// Fetch location
		return {type:'exploration',
		gameTime: settings.gameTime,
		createdOn: new Date(), 
		description:'Having no other recourse the characters find themselves in comabt.',
		prompt: 'Boss battles are major confrontations that occur at the end of a dungeon or quest line. Boss battles typically involve powerful enemies with unique abilities and attacks. These encounters can be challenging and require the players to use all of their skills and abilities in order to succeed.'}
	}
	if (settings.type =='role-play'){
		// Fetch location
		return {type:'exploration',
		gameTime: settings.gameTime,
		createdOn: new Date(), 
		description:'While passing the time...',
		prompt: 'Role-playing encounters involve acting out scenes with the other players or with the game master. These encounters can be used to develop the characters personalities, motivations, and relationships with each other. Role-playing encounters can also be used to advance the plot of the game or to resolve conflicts between the characters.'}
	}
}

async function encounter_response (settings) {
	console.log('Encounter Response Generator '+settings.type+' '+settings.scene_length)
    console.log(settings)
	var prompt = '';
	var classify_character_action = combat_styles().join(',');
	// var combat_tactics = combat_tactics();
    var combat_tactic = combat_tactics({'single':true})
    var prompt_id = ''

	if (settings.tilt!=''){
        console.log('TILT ENCOUNTER')
        console.log(settings.tilt)
        prompt = 'You are a Game Master in a roleplaying game,'+
        ' The player has just rolled a '+settings.tilt+' on their action'+
        '\n Here is the last thing the players comment that describes what they are trying to acomplish. '+settings.content+
        '\n\n Here is more info for what has happened. '+settings.content+' .'+
        '\n Here is the last response in the game. '+settings['more_context']+
        'Describe the outcome of the action in a way that reflects the players roll'
        // '\n Knowing that respond to the players last comment with a response text as an NPC or something in the world that shows the outcome of the action.'
        return {'prompt':prompt}
    }
    if (settings.type =='start'){
			if(settings.introduce_npc == true){
			console.log('Introduce an NPC to guide them');
			prompt_id = 'start_intro_npc_guide'
			prompt = 'You are a Game Master in a roleplaying game, the character has just met a new npc.'+
			'\n Here are the details of the npc.'+settings.introduction+
			'\n Here is the last response in the game. Given this respond with an short introduction of the npc.' 
			}
			if(settings.introduce_rumor == true){
				console.log('Generate Rumors 2')
				prompt_id = 'start_intro_rumor'
				prompt = 'You are a Game Master in a roleplaying game, the character is in a city looking for information and is talking to an npc.'+
				'\n Here are the details of the npc.'+settings.npc_info+
				'\n Here is the last thing the player wrote. '+settings.content+
				'\n Given this respond with the npc descirbing the rumor described here. '+settings.rumors_info; 
			}
			if('encounters' in this.character && this.character['encounters'].length >= 3 && (settings.scene_length % 3 === 0)){
				console.log('Companion Urging to go follow the next rumor')
				prompt_id = 'start_npc_next_rumor'
				prompt = 'You are a Game Master in a roleplaying game, the character named '+settings.chracter_name+
				' is in a city and has been gathering information and resources to support them in their quest. Their companion is growing impatient and wants to urge them on to leave.'+
				'\n Here are the details of their npc companion.'+settings.companion_info+'.'
				'\n  Here is the last thing the player wrote. '+settings.content+
				'\n Given this respond with the npc describing urgency in leaving to pursue this rumor. '+settings.next_rumor
			}
		return {
			'prompt':prompt,
			'prompt_id': prompt_id
		}
	}
		// Creature outside of creature scene.
    if(settings.type !='creature' && settings.scene_length > 6 && typeof(settings.active_creature) !== 'undefined' && settings.active_creature !=''){
		console.log(settings.active_creature)
		console.log('General Creature Messes with Character');
		prompt_id = 'creature_outside'
		prompt = 'You are a Game Master in a roleplaying game, the character and NPCs are interacting with a creature who is acting '+settings['scene_status']+'.'+
		'\n Here are the details of the creature.'+settings.active_creature+
		'\n Here is the last response in the game.'+settings.content+
		' . Given this respond with a short text that describes an action or response the creature would take.'
		// This give epic battle scenes.
		// ' . Given this respond with text that describes an action or response the creature would take.'
		return {'prompt':prompt,'prompt_id':prompt_id}
	}
	// Firing at wrong time. shorten to 1
	if(settings.scene_length == 1){
		console.log('Companion Small Talk on Current Rumor')
		// Fires at 12 not fun.
		// Old logic ||  (settings.type !='combat' && settings.scene_length % 6 === 0) && (settings.scene_length < 12))
		prompt = 'You are a Game Master in a roleplaying game, a player is playing a character named '+settings.character_name+
		'\nThis was the description for the scene.'+settings.current_description+
        '\nThe player is currently pursing this rumor,'+settings.current_rumor+
		'\n Their companion is on a journey with them and makes comments about the rumor they are following.'+
		'\n Here are the details of their npc companion.'+settings.companion+
		'\n Here is the last thing the player wrote. '+settings.content+
		'\n Given this respond with short text as the Companion, responding to the last thing the player wrote but also talking about the rumor or comparing it to something they had done or heard of in the past.'+
        '\n Be descriptive of the current obstacle, such as introducing a proper name for an opponent or item. '
		return {'prompt':prompt}
	}

	if (settings.type =='creature'){
		console.log('In Creature');
		if(settings.introduce_creature && settings.scene_length > 6){
		console.log('Introduce a Creature!');
		prompt_id = 'creature_intro'
		prompt = 'You are a Game Master in a roleplaying game, the character has just run into a new creature.'+
		' Here are the details of the creature.'+settings.intro_creature+
		'Here is the last response in the game.'+settings.content+' Given this respond with an short introduction of the creature.' 
		}
		if(settings.scene_length > 6  && typeof(settings.active_creature) !== 'undefined' && settings.active_creature !=''){
			console.log('Creature Messes with Character 1');
			prompt_id = 'creature_interaction_first'
			prompt = ' You are a Game Master in a roleplaying game, a player is playing a character named '+settings.character_name+'.\n'+
            '\n The character and NPCs are interacting with a creature who is acting '+settings['scene_status']+'.\n'+
			'\n Here is the current location of the scene '+settings.current_location+'.'+
			'\n Here are the details of the creature.'+settings.active_creature+
            '\n Here are the details of the NPC companion. '+settings.companion+
			'\n Here is the last response in the game. '+settings.content+
			'\n Given this respond with text that describes an action or response the creature and/or NPCs in the scene would take.' 
			}else{
            console.log('Creature Messes with Character 2');
            prompt_id = 'creature_interaction_second'
            prompt = 'You are a Game Master in a roleplaying game, a player is playing a character named '+settings.character_name+
             '\n The character is interacting with a creature who is acting '+settings['scene_status']+'.'+
            '\n Here is the current location of the scene. '+settings.current_location+'.'+
            '\n Here is the twist on the scene. '+settings.current_twist+'.'+
            '\n Here are the details of the NPC companion. '+settings.companion+
            '\n Here is the last response in the game. '+settings.content+
            '\n Given this respond with text that describes an action or response the creature and/or NPCs would take.' 
            }

		return {'prompt':prompt, 'prompt_id':prompt_id}
	}
	if (settings.type =='exploration'){
		// Fetch location
		console.log('Exploration Section')
		prompt_id = 'exploration'
		if(settings.scene_length <= 6){
			console.log('Exploration short');
			prompt = 'You are a Game Master in a roleplaying game, the character is in an exploration encounter.'+
			'\n Last Response: '+settings.content+
			'\n Current Location: '+settings.location+
			'\n Current Scene: '+settings.current_rumor+''+
			'\n Rumor Twist: '+settings.current_twist+''+
            '\n NPC companion who is traveling with them: '+settings.companion+
			'\n Given that respond with something that moves the game foreward.' 
		}
		if(settings.scene_length > 6){
			console.log('Exploration long');
			prompt = 'You are a Game Master in a roleplaying game, the character is in an exploration encounter.'+
			'\n Last Response: '+settings.content+
			'\n Stakes: '+settings['scene_status']+
			'\n Current Rumor: '+settings.current_rumor+''+
			'\n Rumor Twist: '+settings.current_twist+''+
            '\n NPC companion who is traveling with them: '+settings.companion+
			'\n Given that respond with something that moves the game foreward.' 
		}
		if('challenge' in settings && settings.scene_length < 4){
			console.log('Challenge')
			prompt = 'You are a Game Master in a roleplaying game, the character is in an exploration encounter. Introduce the Challenge but don\'t solve it'+
			'\n Last Response: '+settings.content+
			'\n Current Rumor: '+settings.current_rumor+''+
			'\n Challenge: '+settings.challenge+''+
            '\n NPC companion who is traveling with them: '+settings.companion+
			'\n Introduce the challenge to the player.' 
		}
		if('clue' in settings && settings.scene_length > 4){
			prompt_id = 'exploration_clue_long'
			console.log('Challenge Resolution Check')
			prompt = 'You are a Game Master in a roleplaying game,a player is playing a character named '+settings.character_name+
			'.\n The character is in an exploration encounter. The character is suppose to solve a puzzle, check to see if they have solved it, and then respond accordingly. '+
			'\n Do not solve the puzzle for the player. Do not play as the character.'+
			// '\n\n Last Response: '+settings.content+
			'\n\n'+settings.more_context+
			'\n\n Current Rumor: '+settings.current_rumor+''+
			'\n\n Challenge: '+settings.challenge+''+
			'\n\n Clue only give if they ask for help: '+settings.clue+''+
			'\n\n Solution: '+settings.solution+''+
            '\n\n NPC companion who is traveling with them: '+settings.companion+
			'\n\n Given the players last response and context respond with something that moves the game foreward.' 
		}
		return {'prompt':prompt, 'prompt_id':prompt_id}
	}
	if (settings.type =='puzzle'){
		console.log('Puzzle Not Created')
		// Fetch location
		//
	}
	if (settings.type =='social'){
		// Fetch location
		console.log('Social Section')

		if(settings.scene_length > 6){
			prompt_id = 'social_twist'
			console.log('Social with a twist');
			prompt = 'You are a Game Master in a roleplaying game, the character is in a social encounter.'+
            '\n The scene the player is in: '+settings.current_description+
			'\n Here is the last response in the game.'+settings.content+' . Given this respond with text that describes a social action or response NPCs would take.'+
			'\n Here is the rumor they are exploring in the area: '+settings.current_rumor+''+
			'\n Here is the twist on the rumor: '+settings.current_twist+''+
            '\n Here are the details of the NPC companion. '+settings.companion+
			'\n Given this respond with the twist.' 
		}
		if(settings.scene_length > 8){
			console.log('Social base');
			prompt_id = 'social_base'
			prompt = 'You are a Game Master in a roleplaying game, a player is playing in a social scene as the character '+settings.character_name+'.'+
            '\n Current Location: '+settings.current_location;+'. '
			if(settings.active_npc!=''){
              prompt = prompt+'The player is currently interacting with an NPC, the NPC has already introduced themselves, here is the NPCS info. '+settings.active_npc
            }
			prompt = prompt+' \n Players Comment:'+settings.content+' .'+
			// '\n Rumor: '+settings.current_rumor+''+
			'\n Scene Twist: '+settings.current_twist+''+
            '\n NPC Companion: '+settings.companion+
			'\n Respond to the player\'s last comment with a short response text. Your response should, Introduce new information, present a challenge, or offer a choice to the player.'
            // '\n Knowing that respond to the players last comment with a short response text as an NPC or something in the world that helps drive the story forward' 
		}
		return {'prompt':prompt, 'prompt_id':prompt_id}
	}
	if (settings.type =='combat'){
		// Fetch location
        console.log('In Combat');
        console.log(settings)

        if(settings.introduce_creature && settings.scene_length > 6){
        console.log('Introduce an Opponent!');
        prompt_id = 'combat_intro'
        prompt = 'You are a Game Master in a roleplaying game, the character is in a combat scene and has just run into a new NPC who they will do conflict with.'+
        // ' Here are the details of the opponent.'+settings.intro_+
        'Here is the last response in the game.'+settings.more_context+' Given this respond with an short introduction of the npc.' 
        }
        if(settings.scene_length > 6  && typeof(settings.active_npc) !== 'undefined' && settings.active_npc !=''){
            console.log('Opponent Messes with Character 1');
            prompt_id = 'combat_interaction_one'
            prompt = ' You are a Game Master in a roleplaying game, a player is playing a character named '+settings.character_name+'.\n'+
            '\n This is a combat scene, so the player will need to fight something to win.'+
            '\n The character is interacting with a NPC who is acting '+settings['scene_status']+'.\n'+
            '\n . Here is the current location of the scene '+settings.current_location+'.'+
            '\n Here are the details of the NPC Enemy.'+settings.active_npc+
            '\n Here are the details of the NPC companion. '+settings.companion+
            '\n Here is the last response in the game. '+settings['more_context']+' . Given this respond with text that describes an action or response the NPCs in the scene would take.' 
            }else{
            console.log('Combat 2');
            prompt_id = 'combat_interaction_two'
            prompt = 'You are a Game Master in a roleplaying game, a player is playing a character named '+settings.character_name+
             '\n This is a combat scene, so the player will need to fight something to win.'+
             '\n The character is interacting with an NPC who is acting deadly.'+
            '\n . Here is the current location of the scene. '+settings.current_location+'.'+
            '\n Here are the details of the NPC Enemy.'+settings.active_npc+
            '\n Here are the details of the NPC companion. '+settings.companion+
            ' . Here is the twist on the scene. '+settings.current_twist+'.'+
            '\n Here is the last response in the game. '+settings['more_context']+
            '\n Given this respond with text that describes an action or response the Enemy, Companion, or NPCs would take.'+
            '\n Avoid using any formating.' 
            }

        return {'prompt':prompt, 'prompt_id':prompt_id}
	}
	if (settings.type =='boss'){
		// Player should know who the boss is before the scene.
        console.log('boss')
	if(settings.scene_length > 6 && settings.scene_length < 11 && settings.doom < 10 ){
			console.log('Boss Fight Troublesome');
			prompt_id = 'boss_troublesome'
			prompt = 'You are a Game Master in a roleplaying game, the character is in a Boss Encounter.'
			if(settings.active_npc!=''){
              prompt = prompt+'The Boss is trying to be troublesome but not deadly, here is the Boss info. '+settings.active_npc
            }
			prompt = prompt+' Here is the last response in the game.'+settings.content+' . Given this respond with text that describes a response or action the boss or thier minions would take.'+
			' Here is the rumor they are exploring in the area.'+settings.current_rumor+''+
			' Here is the twist on the rumor'+settings.current_twist+''+
			' Given this respond with short text to drive the scene forward.' 
		return {'prompt':prompt, 'prompt_id':prompt_id}
		}
	if(settings.scene_length > 12 || settings.doom < 10){
			console.log('Boss Fight Stakes Increased');
			prompt_id = 'boss_raise_stakes'
			prompt = 'You are a Game Master in a roleplaying game, the character is in a Boss Encounter.'
			if(settings.active_npc!=''){
              prompt = prompt+'The Boss is trying to be deadly, here is the Boss info. '+settings.active_npc
            }
			prompt = prompt+' Here is the current tactic the Boss is using. '+combat_tactic+' .'+
            ' Here is al list of character action categories: '+classify_character_action+'.'
            if(settings.tilt!=''){
              prompt = prompt+ ' The player '+settings.tilt+' this attempt, '+settings.content
            }else{
                prompt = prompt+ ' Here is the last response in the game.'+settings.content
            }
			prompt = prompt+ ' . Given this respond with a short text that describes a response or action the boss or thier minions would take.'
			// ' Here is the rumor they are exploring in the area.'+settings.current_rumor+''+
			// ' Here is the twist on the rumor'+settings.current_twist+' '+
			// ' Given this respond with short text to drive the scene forward.' 
		return {'prompt':prompt,'object':true}
		}
		
	}
	if (settings.type =='role-play'){
		// Fetch location
		console.log('Role Play')
		return {type:'exploration',
		gameTime: settings.gameTime,
		createdOn: new Date(), 
		description:'While passing the time...',
		prompt: 'Role-playing encounters involve acting out scenes with the other players or with the game master. These encounters can be used to develop the characters personalities, motivations, and relationships with each other. Role-playing encounters can also be used to advance the plot of the game or to resolve conflicts between the characters.'}
	}
}

export {
	generate_encounters, encounter_response
}