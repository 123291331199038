<template>
  <div class="home container">
    <img style="z-index: 0; left:0; width: 100%; position: fixed;  width: 100%; white-space: nowrap; overflow-x: scroll;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/CastleIntro.jpg">
    <div class='row justify-content-md-center' style="z-index:1;">
      <div class="col-xs-12 col-md-12 col-12" style="position: relative;">
        
        <h1 style="color:white; margin-top:60px;">Pocket RPG</h1>
      </div>
    </div>
    <div class='row justify-content-md-center'>
      <div class="col-xs-12 col-md-12 col-12">
            <span class="shimmer" style="color:white;">A collborative RPG..</span>
           
          </div>
        </div>
<div class='row justify-content-md-center' style="z-index:1; position: relative;">
  <div class="col-xs-12 col-md-12 col-12">
    
      <router-link class="btn btn-primary" to='/login' style='color:white; text-decoration:none;'>Get Started</router-link>

  </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default{
    metaInfo: {
    script: [{
        rel: "preload",
        src: "https://unpkg.com/zdog@1/dist/zdog.dist.min.js"
      },
      {rel:"preload",
      src:"https://unpkg.com/typewriter-effect@latest/dist/core.js"}]
    }
  }
</script>