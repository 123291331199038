<template> 
    <div class="about container">
     <img style="z-index: 0; left:0; width: 100%; position: fixed;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/CastleIntro.jpg">
     <div class="row justify-content-md-center " style='margin-bottom: 8px;'>
     <nav class="navbar-fixed-top navbar fixed-top navbar-expand-lg navbar-top justify-content-center" >
      <router-link class="navbar-brand" to="/main" style="font-size: 14px;">
    <!-- <img src="/docs/4.0/assets/brand/bootstrap-solid.svg" width="30" height="30" class="d-inline-block align-top" alt=""> -->
    <div class='pocket-brand-wrap ' style="width:20px; height:20px; float:left; border-radius: 3px; margin-right:4px; ">
      <div style=" padding:1px;"><i class="ra ra-perspective-dice-random" style="font-size: 14px;"></i></div></div>
    Pocket RPG
  </router-link>
    </nav>
      </div>

    <div class="row justify-content-md-center" style="margin-top:60px; z-index: 1;" >
      <div class="col-xs-12 ">
    <h1 class="outside-white" style="position: relative;">Adventure</h1>
  </div>
</div>
</div>
 <SingleAdventure :adventure_id="this.$route.params.id"></SingleAdventure>
 <div class="row justify-content-md-center" style="margin-top:60px; z-index: 1;" >
      <div class="col-xs-12 col-6 ">
  <div class="card" >
    <button  @click.prevent="toggleInvite()" class="btn btn-danger btn-sm" button>Invite</button>
 <SingleInvite v-if="showInvite" @toggleInvite="toggleInvite" :info="singleInvite" :adventureId="this.$route.params.id" :showType="'adventure'" :type="'adventure'" :query="this.$route.params.id"></SingleInvite>
 <ListInvites :adventure_id="this.$route.params.id" :showType="'adventure'" :query="this.$route.params.id"></ListInvites>
</div>
</div>
</div>
 
</template>

<script>
// { attributionControl:false }
import SingleAdventure from "./SingleAdventure.vue";
import ListInvites from "../ListInvites.vue";
import SingleInvite from "../SingleInvite.vue";
export default {
        components: {
            SingleAdventure,
            ListInvites,
            SingleInvite
        },
       props: {
        
        },
         data() {
            return {
                showInvite:false
              }
            },
              methods: {
                toggleInvite(){
                  this.showInvite = !this.showInvite
                }
        }
      }
</script>