<template>
  <div class="col-xs-12 col-md-4 card" style="margin-bottom: 60px;">
        <h4 style="margin-top: 20px;" v-on:click.prevent="toggleMarquee()" >
          <div class="small-image float-start" style="margin-left:20px;"><img v-if="npc.src" class=""  :src="npc.src" :style="characterImage(npc.src)">
        <img v-else  class="small-image float-left" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png"></div>

      <div class="float-start">{{npc.name || ''}} - NPC</div></h4>
    <div class="NPC-card">
      
      <div class="card-body card-info">
        <div v-if="showMarquee" class="marquee">
        <img v-if="npc.src" class="card-image" :src="npc.src">
        <img v-else class="card-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png" alt="Card image cap">
        </div>
        <div>
          <button class="btn btn-sm btn-primary" v-if="$route.query.gm" v-on:click="playAsNPC(npc)">Play AS</button>
        <br>
        <h6>Class </h6><hr>
        <div style="">{{npc.class || ''}}</div>
        <br>
        <h6>Background </h6><hr>
        <div style="text-align: left;">{{npc.background}}</div>
        <br><br>
        <h6>Apperance</h6><hr>
        <div style="text-align: left;">{{npc.appearance}}</div>
        <br><br>
        <h6>Personality</h6><hr>
        <div style="text-align: left;">{{npc.personality}}</div>
        <br><br>
        <h6>Skills</h6><hr>
        <div v-if="npc.skills" >
        <div class="more_info" v-for="skill in npc.skills"  v-bind:key='skill' style="clear:both;">
          <div class="" ><span v-if="skill.level">{{skill}}</span><span v-else>{{skill}}</span></div>
        </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
   //removed
    export default {
       props: {
          npc: {
            type: Object,
            default: () => {}
          }
        },
        emits: ["setPlayAs"],
         data() {
            return {
                showMarquee: false,
                updatingNPC: false,
              }
            },
          methods: {
          playAsNPC(npc){
            console.log('emit')
            npc['type'] = 'npc'
            this.$emit('setPlayAs', npc);
          },
          toggleMarquee(){
            this.showMarquee = !this.showMarquee;
          },
          characterImage(src){
      var response = '';
      var calc = {'y':0,'x':0,'height':0,'width':0}
      var pop = src.split('?')
      pop = pop[1]
      pop = pop.split('&')
      pop.forEach(function(pair) {
        var key = pair.split('=')[0];
        var value = pair.split('=')[1];
        calc[key] = value;
      });
      response = 'position:absolute; left:-'+calc['x']+'px; top:-'+calc['y']+'px; height:'+Math.round(calc['height'])+'px; width:'+Math.round(calc['width'])+'px;';
      // console.log(response)
      return response
    },
        }
      }
</script>